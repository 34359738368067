import { RouterProvider } from 'react-router-dom'
import { App as UILibApp } from 'components'
import AppTheme from 'AppTheme'
import router from 'router'

const App = () => (
  <AppTheme>
    <UILibApp>
      <RouterProvider router={router} />
    </UILibApp>
  </AppTheme>
)

export default App
