import * as styles from './Header.styles'
import {
  LogoEhInlineColor,
  mediaQueryHookQueries,
  RouterLink,
  useMediaQuery,
} from '../..'
import Icon, { IconProps } from '../../base/Icon'
import HeaderCenteredContent from './components/HeaderCenteredContent'
import HeaderSplitContent from './components/HeaderSplitContent'
import testLabels from './Header.labels'

interface HeaderWithBackButton {
  backButtonLink?: string
  closeButtonLink?: never
}

interface HeaderWithCloseButton {
  backButtonLink?: never
  closeButtonLink?: string
}

interface HeaderLogoProps {
  logo?: IconProps['icon']
}

const HeaderLogo = ({ logo }: HeaderLogoProps) => {
  const isXs = useMediaQuery(mediaQueryHookQueries.xs)
  const logoSize = isXs ? 28 : 36
  const defaultLogo = <LogoEhInlineColor />

  return <Icon icon={logo ?? defaultLogo} size={logoSize} />
}

export type HeaderProps = {
  transparent?: boolean
  logoRoute?: string
  logo?: IconProps['icon']
} & (HeaderWithBackButton | HeaderWithCloseButton)

const Header = ({ logo, logoRoute, transparent }: HeaderProps) => {
  const isXs = useMediaQuery(mediaQueryHookQueries.xs)
  const isMd = useMediaQuery(mediaQueryHookQueries.md)
  const isLg = useMediaQuery(mediaQueryHookQueries.lg)

  const getContent = () => {
    const commonProps = {
      logo: (
        <RouterLink
          to={logoRoute ?? myESET.envData.HomePortalUrl}
          data-label={testLabels.commonLayoutHeaderDeeplinkLogoLink}
        >
          <HeaderLogo logo={logo} />
        </RouterLink>
      ),
    }

    if (isLg) return <HeaderSplitContent {...commonProps} />

    return <HeaderCenteredContent {...commonProps} />
  }

  return (
    <header css={[styles.header(isMd), styles.headerBackground(transparent)]}>
      <div css={[styles.headerContent(isXs)]}>{getContent()}</div>
    </header>
  )
}

export default Header
