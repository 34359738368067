import Bowser from 'bowser'
import { useEffect, useState } from 'react'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import routes from 'routes'
import FallbackPage from './components/FallbackPage'

const deeplinkConfigs = new Map<
  string,
  { intentUrl: string; deeplinkFallbackUrl: string }
>([
  [
    'ems/token',
    {
      intentUrl: myESET.envData.EMSTokenIntentUrl,
      deeplinkFallbackUrl: myESET.envData.EMSTokenFallbackUrl,
    },
  ],
])

const interpolate = (template: string, key: string, value: string): string =>
  template.replace(`{${key}}`, value)

const bowser = Bowser.getParser(window.navigator.userAgent)

const DeeplinkTest = () => {
  const { product, protocol } = useParams()
  const [queryParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()

  const [intentUrl, setIntentUrl] = useState<string>('')
  const [deeplinkFallbackUrl, setDeeplinkFallbackUrl] = useState<string>('')
  const [deeplinkUrl, setDeeplinkUrl] = useState<string>('')

  const triggerIntent = (url: string) => {
    const element = document.createElement('a')
    element.href = url
    element.click()
  }

  useEffect(() => {
    const deeplinkConfig = deeplinkConfigs.get(`${product}/${protocol}`)

    if (!deeplinkConfig) {
      navigate(routes.invalidLink)
      return
    }

    setIntentUrl(
      interpolate(
        deeplinkConfig.intentUrl,
        'tokenData',
        queryParams.get('data') ?? ''
      )
    )

    if (bowser.getOSName(true) === 'android') {
      triggerIntent(intentUrl)
    }

    setDeeplinkUrl(
      `https://${myESET.envData.DeeplinkHost}${location.pathname}${location.search}`
    )

    setDeeplinkFallbackUrl(
      interpolate(
        deeplinkConfig.deeplinkFallbackUrl,
        'deeplink',
        // The deeplink URI is encoded twice since it's nested 2 levels deep inside the fallback URL query params
        encodeURIComponent(encodeURIComponent(deeplinkUrl))
      )
    )
  }, [product, protocol, intentUrl, deeplinkFallbackUrl, deeplinkUrl])

  return (
    <FallbackPage
      deeplinkFallbackUrl={deeplinkFallbackUrl}
      product={product}
      deeplinkUrl={deeplinkUrl}
    />
  )
}

export default DeeplinkTest
