import { Divider, Link, Spacer, Text } from 'components'
import SimpleCenteredTemplate from 'components/template/SimpleCenteredTemplate'
import { QRCodeSVG } from 'qrcode.react'
import i18n from 'localization/i18n'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'
import testLabels from '../DeeplinkValidation.labels'

interface FallbackPageProps {
  deeplinkFallbackUrl: string
  product?: string
  deeplinkUrl: string
}

const FallbackPage = ({
  deeplinkFallbackUrl,
  product,
  deeplinkUrl,
}: FallbackPageProps) => {
  const { t } = useTranslation()

  return (
    <SimpleCenteredTemplate size="small" title={t('FallbackPageTitle')}>
      <Text>{t(`FallbackPageDescription_${product}`)}</Text>
      <Spacer
        type="my-5"
        css={css`
          width: 100%;
        `}
      >
        <Divider />
      </Spacer>
      {t('FallbackPageGooglePlayStoreButtonDescription')}
      <Spacer type="m-5">
        <Link
          href={deeplinkFallbackUrl}
          testDataLabel={testLabels.googlePlayStoreLink}
        >
          <img
            src={`${myESET.envData.HomeAssetUrl}/images/googlePlayBadges/${i18n.language}/google-play-badge.png`}
            alt="Get it on Google Play"
            width={160}
          />
        </Link>
      </Spacer>
      <Spacer type="mb-5">
        <Text>{t('FallbackPageGooglePlayStoreQRCodeDescription')}</Text>
      </Spacer>
      <Spacer type="m-5">
        <QRCodeSVG size={200} value={deeplinkUrl} />
      </Spacer>
    </SimpleCenteredTemplate>
  )
}

export default FallbackPage
